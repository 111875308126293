const circleData = [
  // Circles variant 0
  [
    {
      id: 11,
      y: 'top',
      x: 'left',
      color: 'var(--color-citrus3)',
      hollow: true,
      big: false,
    },
    {
      id: 12,
      y: 'middle',
      x: 'right',
      color: 'var(--color-champ5)',
      hollow: false,
      big: true,
    },
    {
      id: 13,
      y: 'bottom',
      x: 'left',
      color: 'var(--color-iris5)',
      hollow: false,
      big: false,
    },
  ],
  // Circles variant 1
  [
    {
      id: 21,
      y: 'middle',
      x: 'right',
      color: 'var(--color-citrus3)',
      hollow: false,
      big: true,
    },
    {
      id: 22,
      y: 'bottom',
      x: 'left',
      color: 'var(--color-iris5)',
      hollow: false,
      big: false,
    },
  ],
  // Circles variant 2
  [
    {
      id: 31,
      y: 'top',
      x: 'right',
      color: 'var(--color-citrus3)',
      hollow: true,
      big: false,
    },
    {
      id: 32,
      y: 'middle',
      x: 'left',
      color: 'var(--color-iris5)',
      hollow: false,
      big: true,
    },
    {
      id: 33,
      y: 'bottom',
      x: 'right',
      color: 'var(--color-champ5)',
      hollow: false,
      big: false,
    },
  ],
  // Circles variant 3
  [
    {
      id: 41,
      y: 'top',
      x: 'left',
      color: 'var(--color-iris5)',
      hollow: true,
      big: false,
    },
    {
      id: 42,
      y: 'middle',
      x: 'right',
      color: 'var(--color-citrus3)',
      hollow: false,
      big: true,
    },
    {
      id: 43,
      y: 'bottom',
      x: 'left',
      color: 'var(--color-champ5)',
      hollow: false,
      big: false,
    },
  ],
];

export default circleData;
