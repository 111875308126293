import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import ParallaxWrapper from './ParallaxWrapper';
import circleVariants from './circleVariants';
import { CircleVariantsProps, StyledScrollingCirclesProps, ScrollingCirclesProps } from './types';

const StyledScrollingCircles = styled.div<StyledScrollingCirclesProps>`
  z-index: -1;
  position: absolute;
  width: 100%;
  top: -4.5rem;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  ${(props) =>
    props.tooLow
      ? css`
          height: 150rem;
        `
      : css`
          height: 280rem;
        `};
`;

const ScrollingCircles: React.FC<ScrollingCirclesProps> = ({ circleIndex }) => {
  const [tooLow, setTooLow] = useState(null);
  // Sets circle sequence on render
  useEffect(() => {
    setTooLow(document.body.offsetHeight < 4800);
  }, [document.body.offsetHeight]);

  return (
    <StyledScrollingCircles tooLow={tooLow}>
      {circleVariants[circleIndex].map(({ id, y, x, color, hollow, big }: CircleVariantsProps) => (
        <ParallaxWrapper
          key={id}
          position={{
            y,
            x,
          }}
          color={color}
          hollow={hollow}
          big={big}
          tooLow={tooLow}
        />
      ))}
    </StyledScrollingCircles>
  );
};

export default ScrollingCircles;
