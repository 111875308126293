import { ValuesProps, CircleSettingsProps, PositionProps, GetXPositionProps } from './types';
import isIosSafari from '$utils/isIosSafari';

const getXPosition = ({ x, left, right }: GetXPositionProps): [number, number] => {
  if (isIosSafari()) return [0, 0];
  if (x === 'right') return right;
  return left;
};

// Position and parallax values for all position variants
const getCircleSettings = (position: PositionProps): CircleSettingsProps => {
  const { y, x } = position;

  const values: ValuesProps = {
    top: {
      y: {
        name: 'top',
        topMin: -525,
        topMax: -850,
        parallax: !isIosSafari() ? [-50, 50] : [50, 50],
      },
      x: {
        name: x,
        parallax: getXPosition({ x, left: [50, 0], right: [-50, 0] }),
      },
    },
    middle: {
      y: {
        name: 'middle',
        topMin: 450,
        topMax: 700,
        parallax: !isIosSafari() ? [-25, 25] : [0, 0],
      },
      x: {
        name: x,
        parallax: getXPosition({ x, left: [10, 0], right: [-10, 0] }),
      },
    },
    bottom: {
      y: {
        name: 'bottom',
        topMin: 3050,
        topMax: 3400,
        parallax: !isIosSafari() ? [-20, 30] : [0, 0],
      },
      x: {
        name: x,
        parallax: getXPosition({ x, left: [0, -10], right: [0, 10] }),
      },
    },
  };

  if (y === 'top') {
    return values.top;
  }
  if (y === 'middle') {
    return values.middle;
  }
  if (y === 'bottom') {
    return values.bottom;
  }
  return null;
};

export default getCircleSettings;
