import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { StyledScrollingCircleProps } from './types';
import scalingSize from '$utils/scalingSize';

const FadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const StyledScrollingCircle = styled.div<StyledScrollingCircleProps>`
  // Circle size
  ${(props) => css`
    ${scalingSize('width', props.size.min, props.size.max)};
    ${scalingSize('height', props.size.min, props.size.max)};
  `}

  border-radius: 100%;
  pointer-events: none;
  margin-left: auto;
  animation: ${FadeIn} ease-out 1.2s;

  // if (hollow) use border, else if (!hollow) use background color
  ${(props) =>
    props.hollow
      ? css`
          ${scalingSize('border-width', props.size.minBorderWidth, props.size.maxBorderWidth)};
          border-style: solid;
          border-color: ${props.color};
        `
      : css`
          background-color: ${props.color};
        `}
`;

const ScrollingCircle: React.FC<StyledScrollingCircleProps> = ({ hollow, color, size }) => (
  <StyledScrollingCircle hollow={hollow} color={color} size={size} />
);

export default ScrollingCircle;
