import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import styled, { css } from 'styled-components';
import scalingSize from '$utils/scalingSize';
import { ParallaxWrapperProps, StyledDivProps } from './types';
import getCircleSettings from './getCircleSettings';
import ScrollingCircle from './ScrollingCircle';

const StyledDiv = styled.div<StyledDivProps>`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 90rem;
  left: 0;
  right: 0;
  ${(props) =>
    props.circleSettings.y.name === 'bottom' && props.tooLow
      ? css`
          display: none;
        `
      : null};

  // Y circle offset
  ${(props) =>
    css`
      ${scalingSize('top', props.circleSettings.y.topMin, props.circleSettings.y.topMax)};
    `}

  .circle-1 {
    position: absolute;
    margin: 0;
    top: 12rem;

    // X circle offset
    ${(props) =>
      css`
        ${scalingSize(props.circleSettings.x.name, props.size.minOffset, props.size.maxOffset)};
      `}
  }

  &.middle-circle,
  &.bottom-circle {
    display: none;

    @media screen and (min-width: 640px) {
      display: block;
    }
  }

  &.bottom-circle {
    ${(props) =>
      props.tooLow
        ? css`
            display: none;
          `
        : null};
  }
`;

const ParallaxWrapper: React.FC<ParallaxWrapperProps> = ({ hollow, color, big, position, tooLow }) => {
  const [circleSettings, setCircleSettings] = useState(null);

  useEffect(() => {
    setCircleSettings(getCircleSettings(position));
  }, []);

  // Size values
  const size = {
    min: big ? 512 : 325,
    max: big ? 1024 : 650,
    minOffset: big ? -356 : -162.5,
    maxOffset: big ? -712 : -325,
    minBorderWidth: big ? 128 : 81.25,
    maxBorderWidth: big ? 256 : 162.5,
  };

  return (
    <>
      {circleSettings && (
        <StyledDiv
          className={`${circleSettings.y.name}-circle`}
          circleSettings={circleSettings}
          size={size}
          tooLow={tooLow}
        >
          <Parallax className="circle-1" y={circleSettings.y.parallax} x={circleSettings.x.parallax} tagOuter="figure">
            <ScrollingCircle hollow={hollow} color={color} size={size} />
          </Parallax>
        </StyledDiv>
      )}
    </>
  );
};

export default ParallaxWrapper;
